







































import {HelferleinEventList} from '@labor-digital/helferlein/lib/Events/HelferleinEventList';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {isUndefined} from '@labor-digital/helferlein/lib/Types/isUndefined';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {AppStoreKeys} from '../../../AppStoreKeys';
import GreenContainer from '../../../Component/Layout/GreenContainer/GreenContainer.vue';
import Accordion from '../../../Component/Misc/Accordion/Accordion.vue';
import AccordionTabSwitch from '../../../Component/Misc/AccordionTabSwitch/AccordionTabSwitch.vue';
import CmsTextLinkBlock from '../../../Component/Misc/Link/CmsTextLinkBlock/CmsTextLinkBlock.vue';
import ComponentProxyAwareMixin from '../../../Mixin/ComponentProxyAwareMixin';
import {JsonLdService} from '../../../Service/JsonLdService';

export default {
    name: 'Faq',
    components: {CmsTextLinkBlock, AccordionTabSwitch, GreenContainer, Accordion},
    mixins: [ComponentProxyAwareMixin],
    props: {
        context: ContentElementContext
    },
    metaInfo()
    {
        return {
            script: [
                JsonLdService.makeFaq(this.tabs)
            ]
        };
    },
    computed: {
        tabs(): PlainObject
        {
            return this.context.data.get('tabs', []);
        },
        classes()
        {
            return {
                'faq--noTitle': this.tabs.length === 1
            };
        },
        stylesTitles()
        {
            return {
                marginTop: '-' + this.titleHeight + 'px'
            };
        },
        stylesContainer()
        {
            return {
                marginTop: this.titleHeight + 'px'
            };
        },
        openCategory()
        {
            return this.opened.category;
        },
        openFaq()
        {
            return this.opened.faq;
        }
    },
    data()
    {
        return {
            titleHeight: 0,
            opened: {
                category: 0,
                faq: 0
            }
        };
    },
    methods: {
        onBreakpointChange()
        {
            if (this.context.store.get(AppStoreKeys.BREAKPOINT).is('>', 'md')) {
                if (isUndefined(this.$refs.switch.$el)) {
                    return;
                }
                this.titleHeight = this.$refs.switch.$el.children[0].scrollHeight - 31;
            } else {
                this.titleHeight = 0;
            }
        },
        setOpenedItemByUid(uid)
        {
            let found = false;

            forEach(this.tabs, (tab, tabIndex) => {
                forEach(tab.contents, (faq, faqIndex) => {
                    if (faq.id === uid) {
                        this.opened.category = tabIndex;
                        this.opened.faq = faqIndex;
                        found = true;
                        return false;
                    }
                });

                if (found === true) {
                    return false;
                }
            });

            if (found === true) {
                this.$nextTick(() => this.$refs['faq-' + uid][0].scrollIntoView({behavior: 'smooth'}));
            }
        }
    },
    mounted()
    {
        this.onBreakpointChange();

        // Register global event listeners
        this.proxy.bind(
            this.context.eventEmitter,
            HelferleinEventList.EVENT_RESIZE_THROTTLED,
            this.onBreakpointChange
        );

        // Open by anchor link
        const matches = window.location.hash.replace('#', '').match(/(\d+)-.+/);
        if (matches && matches.length === 2) {
            this.setOpenedItemByUid(Number(matches[1]));
        }
    }
};
