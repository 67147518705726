var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("green-container", { style: _vm.stylesContainer }, [
    _c(
      "div",
      { staticClass: "faq" },
      [
        _c(
          "accordion-tab-switch",
          {
            ref: "switch",
            staticClass: "faq__accordion",
            class: _vm.classes,
            style: _vm.stylesTitles,
            attrs: {
              context: _vm.context,
              items: _vm.tabs,
              open: _vm.openCategory,
              "item-label": "title",
            },
          },
          [
            _vm._l(_vm.tabs, function (category, index) {
              return _c(
                "template",
                { slot: category.title },
                [
                  _c(
                    "accordion",
                    {
                      attrs: {
                        items: category.contents,
                        open: _vm.openFaq,
                        "item-label": "title",
                      },
                    },
                    [
                      _vm._l(category.contents, function (faq) {
                        return _c(
                          "template",
                          { slot: faq.title },
                          [
                            _c("div", { ref: "faq-" + faq.id, refInFor: true }),
                            _vm._v(" "),
                            _c("cms-text-link-block", [
                              _c("div", {
                                staticClass: "faq__bodyText",
                                domProps: { innerHTML: _vm._s(faq.bodyText) },
                              }),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }